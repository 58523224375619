import React from "react"
import { Box, Heading, Container } from "@theme-ui/components"

const EventMap = ({ block }) => {
  console.log(block)
  const { embedCode, title } = block
  return (
    <Box sx={{ p: { m: [0] } }}>
      <Container>
        <Heading
          as="h3"
          variant="h2"
          sx={{
            fontWeight: 400,
            fontSize: [5, 6, 6, 6],
            mb: [0, 4],
            mt: [0],
            color: "primary",
          }}
        >
          {title}
        </Heading>
        <Box dangerouslySetInnerHTML={{ __html: embedCode }} />
      </Container>
    </Box>
  )
}

export default EventMap
