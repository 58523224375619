import React from "react"
import { graphql } from "gatsby"
import { Box, Container } from "@theme-ui/components"
import Layout from "../components/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { getFeaturedEventPath } from "../utils/path"
import RichContentStructuredText from "../components/richContentStructuredText"
import EventHero from "../components/eventHero"
import EventCountdown from "../components/eventCountdown"
import Video from "../components/blocks/video"
import EventAgenda from "../components/eventAgenda"
import EventSpeakersCollection from "../components/eventSpeakersCollection"
import EventPartnerTiersCollection from "../components/eventPartnersTiersCollection"
import EventMap from "../components/eventMap"
// import EventCta from "../components/blocks/eventCta"

const FeaturedEvent = ({ data: { page, site }, location }) => {
  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getFeaturedEventPath(page, locale),
    }
  })

  return (
    <Layout
      locale={page.locale}
      i18nPaths={i18nPaths}
      theme="dark"
      hideMenu={true}
      location={location}
    >
      <HelmetDatoCms seo={page.seoMetaTags}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <Container sx={{ pb: [0, 0], mt: [8,8,8, "12rem"] }}>
        <EventHero page={page} />
      </Container>
      <Container
        variant="md"
        sx={{
          my: [5, 6, 7],
          p: {
            fontSize: [3, 4],
            strong: { fontWeight: 500 },
          },
          h3: {
            color: "dark",
            fontWeight: 400,
            fontSize: [4, 5],
            strong: {
              color: page.primaryColor?.hex || "#000000",
              fontWeight: 400,
            },
          },
        }}
      >
        <RichContentStructuredText text={page.body} />
      </Container>
      {page.content.map(block => (
        <Box as="section" key={block.id}>
          {block.model.apiKey === "event_map" && <EventMap block={block} />}
          {block.model.apiKey === "event_countdown" && (
            <Container variant="md">
              <EventCountdown
                block={block}
                registrationCta={page.registrationCta}
                registrationLink={page.registrationLink}
                primaryColor={page.primaryColor}
              />
            </Container>
          )}
          {block.model.apiKey === "video" && (
            <Video
              title={block.title}
              embedCode={block.embedCode}
              customHtml={block.customHtml}
              addMarginBottom={block.addMarginBottom}
            />
          )}

          {block.model.apiKey === "event_agenda" && (
            <EventAgenda
              block={block}
              registrationCta={page.registrationCta}
              registrationLink={page.registrationLink}
              primaryColor={page.primaryColor}
            />
          )}
          {/* {block.model.apiKey === "event_cta" && (
            <EventCta
              block={block}
              registrationCta={page.registrationCta}
              registrationLink={page.registrationLink}
              primaryColor={page.primaryColor || { hex: '#000000' }}
            />
          )} */}
          {block.model.apiKey === "event_partners_tiers_collection" && (
            <EventPartnerTiersCollection
              block={block}
              primaryColor={page.primaryColor}
            />
          )}
          {block.model.apiKey === "event_speakers_collection" && (
            <EventSpeakersCollection
              block={block}
              primaryColor={page.primaryColor}
            />
          )}
        </Box>
      ))}
    </Layout>
  )
}

export default FeaturedEvent

export const query = graphql`
  query EventQuery($id: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsFeaturedEvent(id: { eq: $id }) {
      title
      locale
      date
      location
      registrationCta
      registrationLink
      body {
        value
      }
      heroImage {
        gatsbyImageData(width: 1480, placeholder: BLURRED)
      }
      logo {
        url
      }
      text {
        url
      }
      primaryColor {
        hex
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsEventMap {
          id
          title
          embedCode
          model {
            apiKey
          }
        }
        ... on DatoCmsEventPartnersTiersCollection {
          id
          title
          subtitle
          tiers {
            id
            title
            sponsors {
              id
              url
              image {
                gatsbyImageData(height: 90, placeholder: BLURRED)
              }
            }
          }
          model {
            apiKey
          }
        }

        ... on DatoCmsEventSpeakersCollection {
          id
          title
          subtitle
          speakers {
            id
            name
            company
            thumbnail {
              gatsbyImageData(
                placeholder: BLURRED
                height: 360
                imgixParams: { ar: "16:9", fit: "crop" }
              )
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsEventAgenda {
          id
          title
          sessions {
            title
            sessions {
              description
              id
            }
            id
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsVideo {
          id
          title
          embedCode
          customHtml
          addMarginBottom
          model {
            apiKey
          }
        }
        ... on DatoCmsEventCountdown {
          id
          title
          date
          model {
            apiKey
          }
        }
      }
      model {
        apiKey
      }
    }
  }
`
