import React from "react"
import {
  Box,
  Heading,
  Text,
  Grid,
  Flex,
  Link,
  Container,
} from "@theme-ui/components"
import arrow from "../images/list-style.svg"

const EventAgenda = ({
  block,
  registrationCta,
  registrationLink,
  primaryColor,
}) => {
  const { title, date } = block
  return (
    <Container>
      <Flex
        sx={{
          mb: [5, 6],
          p: { m: [0] },
          flexDirection: ["column", "column", "row", "row"],
          justifyContent: "space-between",
          alignItems: ["flex-start", "flex-start", "center"],
        }}
      >
        <Heading
          as="h2"
          variant="h1"
          sx={{
            color: primaryColor.hex,
            fontSize: [5, 5, 6, 6],
            fontWeight: 400,
            mb: [0],
          }}
        >
          {title}
        </Heading>
        {registrationCta && registrationLink && (
          <Link
            href={registrationLink}
            variant="buttons.button"
            target="_blank"
            sx={{
              mt: [4, 5, 0, 0],
              display: "inline-block",
              backgroundColor: primaryColor.hex || "primary",
              color: "light",
            }}
          >
            {registrationCta}
          </Link>
        )}
      </Flex>
      {block.sessions.length > 0 ? (
        <Grid columns={[1, 1, 2, 2]} gap={[4, 4, "64px", "128px"]}>
          {block.sessions.map(session => (
            <Box>
              <Heading
                as="h3"
                variant="h1"
                sx={{
                  fontSize: [4, 5],
                  color: primaryColor.hex,
                  fontWeight: 400,
                  borderTop: "1px solid",
                  borderBottom: "1px solid",
                  borderColor: primaryColor.hex,
                  py: [2],
                  mb: [4, 5],
                }}
              >
                {session.title}
              </Heading>
              {session.sessions.map(session => (
                <Box
                  sx={{
                    borderBottom: "1px solid",
                    borderColor: "#cccccc",
                    mb: [4, 5],
                    p: {
                      fontSize: [1, 2],
                      mb: [1],
                      "&:first-of-type": {
                        color: primaryColor.hex,
                        fontWeight: 500,
                      },
                    },
                    h4: {
                      color: "dark",
                      fontSize: [5],
                      m: [0],
                      fontStyle: 400,
                      fontWeight: 500,
                      lineHeight: 1.25,
                      letterSpacing: "-0.2px",
                    },
                    ul: {
                      pl: [3],
                      li: { mb: [1] },
                    },
                    li: {
                      listStyleImage: `url(${arrow})`,
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: session.description }}
                />
              ))}
            </Box>
          ))}
        </Grid>
      ) : (
        <Box
          sx={{
            fontSize: [6, 7, 7, 7],
            color: primaryColor.hex,
            fontWeight: 400,
            borderTop: "1px solid",
            borderBottom: "1px solid",
            borderColor: primaryColor.hex,
            py: [2],
            mb: [4, 5],
          }}
        >
          Programa en definición
        </Box>
      )}
    </Container>
  )
}

export default EventAgenda
