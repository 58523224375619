import React from "react"
import { Box, Flex, Grid, Text, Heading, Container } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"

const EventSpeakersCollection = ({ block, primaryColor }) => {
  const { title, subtitle, speakers } = block
  return (
    <Container sx={{ my: [4, 4, 6] }}>
      <Grid columns={[1, 1, 1, "1fr 4fr"]} gap={[16, 16, 16, 256]}>
        <Flex
          sx={{
            mb: [0],
            flexDirection: "column",
          }}
        >
          <Heading
            as="h3"
            variant="h2"
            sx={{
              fontWeight: 400,
              fontSize: [5, 6, 6, 6],
              mb: [0, 2],
              mt: [0],
              color: primaryColor.hex || "primary",
            }}
          >
            {title}
          </Heading>
          <Box
            sx={{ a: { color: primaryColor.hex } }}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </Flex>
        <Grid columns={[2, 2, 2, 3]} gap={[2]}>
          {speakers.map(speaker => (
            <Box
              sx={{
                borderTop: "1px solid",
                borderColor: primaryColor.hex || "primary",
                mb: [4],
                pt: [4],
              }}
            >
              {speaker.thumbnail && (
                <GatsbyImage image={speaker.thumbnail.gatsbyImageData} />
              )}
              <Text
                as="p"
                sx={{
                  mt: [3, 3, 3, 3],
                  fontSize: [3, 4, 5, 5],
                  fontWeight: 500,
                }}
              >
                {speaker.name}
              </Text>
              <Text as="p" sx={{ mt: [1, 1] }}>
                {speaker.company}
              </Text>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Container>
  )
}

export default EventSpeakersCollection
